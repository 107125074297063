import React from "react";
import Layout from "component/Layout/Layout";
import ProjectPage from "component/ProjectPage/ProjectPage";
import Seo from "component/seo";
import { graphql } from "gatsby";
import { useSiteUrl } from "utils";
import { Disqus } from "gatsby-plugin-disqus";
import SidebarLeft from "component/Sidebar/SidebarLeft";
import SidebarRight from "component/Sidebar/SidebarRight";

export default function JsProjectPage({ data, location }) {
  const {
    allContentfulProjectJs: { projects },
  } = data;
  const canonicalUrl = `${useSiteUrl()}${location.pathname}`;

  let disqusConfig = {
    url: canonicalUrl,
    identifier: canonicalUrl,
    title: "30 days of JavaScript",
  };

  return (
    <Layout>
      <Seo
        title="Javascript Projects For Beginners"
        image="//images.ctfassets.net/o8rbhyyom3pw/3f9uKDlksJUCVKZtVec55r/fde06afdc3c464062e20b92365a29669/ProjectThumbnail.png"
        titleSample={true}
        canonicalUrl={canonicalUrl}
      />
      <SidebarLeft />
      <div>
        <ProjectPage projects={projects} />
        <Disqus config={disqusConfig} />
      </div>
      <SidebarRight />
    </Layout>
  );
}
export const query = graphql`
  {
    allContentfulProjectJs(sort: { order: ASC, fields: date }) {
      projects: nodes {
        thumbnail {
          gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1, width: 300)
        }
        id
        title
        sourcecode
        linkTutorial {
          ... on ContentfulPosts {
            id
            url
          }
        }
      }
    }
  }
`;
