import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

export default function ProjectItem({ project }) {
  const { linkTutorial, thumbnail, sourcecode, title } = project;
  return (
    <div className="project">
      <div className="project__info">
        {linkTutorial ? (
          <a
            target="_blank"
            rel="noreferrer"
            className="tutorial title truncate"
            href={`https://homiedev.com/${linkTutorial.url}`}
            title={title}
          >
            <h2>{title}</h2>
          </a>
        ) : (
          <h2 className="truncate" title={title}>
            {title}
          </h2>
        )}

        <div className="project__info-footer">
          <a
            href={sourcecode}
            target="_blank"
            rel="noreferrer"
            className="download"
          >
            Download
          </a>
        </div>
      </div>
      <div className="project__thumbnail">
        <a
          target={linkTutorial ? "_blank" : ""}
          rel="noreferrer"
          href={linkTutorial ? `https://homiedev.com/${linkTutorial.url}` : "#"}
        >
          <GatsbyImage alt={title} image={getImage(thumbnail)} />
        </a>
      </div>
    </div>
  );
}
